import React from "react";
import fire from "../../../assets/images/fire.gif";
import {NumberInput} from "../../../components";
import {getTotalDiscount} from "../../../helpers/priceHelper";
import {EventTicket, EventTicketInclusion} from "../../../models/event.model";
import {SelectedEventPayload} from "./eventSlice";

import {Button} from "@/components/ui/button"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"

interface ComponentProps {
    eventTicket: EventTicket;
    payload?: SelectedEventPayload;
    onChange?: (value: any) => void;
    discountPercentage: number;
    currency: string;
    onUpdate?: (value: any) => void;
}

export const EventTicketRow: React.FC<ComponentProps> = ({
                                                             payload,
                                                             eventTicket,
                                                             onChange,
                                                             discountPercentage,
                                                             currency,
                                                             onUpdate,
                                                         }) => {
    const discount = discountPercentage / 100;
    const totalDiscountPrice = Math.floor(parseInt(eventTicket.price) * discount);
    const [open, setOpen] = React.useState(false);

    const getTotalPrice = Math.floor(
        parseInt(eventTicket.price) - totalDiscountPrice
    );

    const handleChange = (value: number) => {
        if (onChange)
            onChange({
                id: eventTicket.id,
                name: eventTicket.name,
                quantity: value,
                price: eventTicket.price,
                perTicketFee: eventTicket.perTicketFee,
                multiplier: eventTicket.multiplier,
                total: getTotalPrice * value,
            });
    };

    // React.useEffect(() => {
    //   if (onChange) {
    //     // console.log("TRIGGER", getTotalPrice * (payload?.quantity || 0));
    //     onChange({
    //       id: eventTicket.id,
    //       name: eventTicket.name,
    //       quantity: payload?.quantity || 0,
    //       price: eventTicket.price,
    //       perTicketFee: eventTicket.perTicketFee,
    //       total: getTotalPrice * (payload?.quantity || 0),
    //     });
    //   }
    // }, [discountPercentage]);

    const isRemainingQtyEqualToZero = eventTicket && eventTicket.remaining === 0;

    return (
        <div className="flex flex-col w-full p-2 border-b">
            <div className="flex items-center justify-between">
                <div
                    className={`font-bold uppercase flex items-center ${
                        isRemainingQtyEqualToZero ? "text-gray-400" : ""
                    }`}
                >
                    <span className="mr-2"> {eventTicket.name}</span>
                    {eventTicket.EventTicketInclusion.length > 0 && (

                        <DialogDemo open={open} setOpen={setOpen} eventTicket={eventTicket}/>


                    )}
                    {` ${isRemainingQtyEqualToZero ? "(SOLD OUT)" : ""}`}
                </div>
                <NumberInput
                    eventTicket={eventTicket}
                    initialValue={payload?.quantity || 0}
                    onChange={handleChange}
                    maxValue={eventTicket.remaining < 8 ? eventTicket.remaining : 8}
                />
            </div>
            <div
                className={`text-sm font-light ${
                    isRemainingQtyEqualToZero ? "text-gray-400" : ""
                }`}
            >
                <div className="flex items-center gap-2">
                    {parseInt(eventTicket.originalPrice) > 0 && (
                        <span className={`line-through`} id="original_price">
              {currency === "PHP" ? "₱" : "$"}
                            {eventTicket.originalPrice}
            </span>
                    )}

                    {parseInt(eventTicket.originalPrice) === 0 &&
                        discountPercentage > 0 && (
                            <span className={`line-through`} id="original_price">
                {currency === "PHP" ? "₱" : "$"}
                                {eventTicket.price}
              </span>
                        )}

                    <span
                        id="price"
                        className={`${
                            (parseInt(eventTicket.originalPrice) > 0 || discountPercentage) &&
                            "text-lg font-semibold text-lightBlue-500"
                        }`}
                    >
            {`${currency === "PHP" ? "₱" : "$"} ${
                discountPercentage === 0
                    ? eventTicket.price
                    : parseInt(eventTicket.price) -
                    getTotalDiscount(
                        parseInt(eventTicket.price),
                        discountPercentage / 100
                    )
            }`}
          </span>
                    {(parseInt(eventTicket.originalPrice) > 0 ||
                        discountPercentage > 0) && (
                        <span
                            id="total_discount"
                            className="p-1 text-xs font-semibold text-white bg-green-500 rounded-sm"
                        >
              {(parseInt(eventTicket.originalPrice) > 0 ||
                      discountPercentage) &&
                  `${
                      Math.floor(
                          parseInt(eventTicket.originalPrice) > 0
                              ? 100 -
                              (parseInt(eventTicket.price) /
                                  parseInt(eventTicket.originalPrice)) *
                              100
                              : 0
                      ) + discountPercentage
                  }`}
                            % OFF
            </span>
                    )}

                    {eventTicket.isHotDeal && (
                        <span className="hotdeal ">
              <span className="fire ">
                <span className="flame ">
                  <div className="absolute ml-3 -bottom-6 w-[40px] h-[40px]">
                    <img
                        src={fire}
                        alt="My GIF"
                        style={{
                            objectFit: "cover",
                            objectPosition: "center",
                            width: "100%",
                            height: "100%",
                        }}
                    />
                  </div>
                  <p className="absolute flex flex-row gap-1 text-xs font-extrabold text-red-500 top-5 drop-shadow-xl shadow-black">
                    <span>HOT</span>
                    <span>DEALS </span>
                  </p>
                </span>
              </span>
            </span>
                    )}
                </div>
            </div>
            {/*<Modal title="Ticket Inclusions" open={open} setOpen={setOpen}>*/}

            {/*</Modal>*/}
        </div>
    );
};


interface DialogDemoProps {
    open: boolean;
    setOpen: (value: boolean) => void;
    eventTicket: EventTicket;
}

export function DialogDemo({open, setOpen, eventTicket}: DialogDemoProps) {
    return (
        <Dialog
            open={open}
            onOpenChange={setOpen}
        >
            <DialogTrigger asChild className={"z-50"}>
                <Button
                    onClick={() => setOpen(true)}
                    className="p-1 text-[10px] border border-1 rounded-md"
                    variant={"ghost"}
                >
                    Inclusions

                </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Inclusions</DialogTitle>
                    <DialogDescription>
                        Inclusions for {eventTicket.name}
                    </DialogDescription>
                </DialogHeader>
                <div className="grid gap-4 py-4">
                    <ol className="p-4">
                        {eventTicket.EventTicketInclusion.map(
                            (eventTicketInclusion: EventTicketInclusion) => {
                                return (
                                    <li>
                  <span className="text-lg font-semibold ">
                    {eventTicketInclusion.name}
                  </span>
                                        {eventTicketInclusion.description && (
                                            <p>- {eventTicketInclusion.description}</p>
                                        )}
                                    </li>
                                );
                            }
                        )}
                    </ol>
                </div>
            </DialogContent>
        </Dialog>
    )
}
